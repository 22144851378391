


















































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Ref, Prop } from "vue-property-decorator";

@Observer
@Component({
  components: {},
})
export default class FixedPriceForm extends Vue {
  @Prop({ default: [] }) offers: any[] = [];
  headers?: any = [
    {
      text: "From",
      sortable: true,
      align: "left",
      value: "",
    },
    {
      text: "Price",
      value: "",
      align: "center",
      sortable: true,
    },
    {
      text: "Time",
      value: "",
      align: "right",
      sortable: true,
    },
  ];
}
